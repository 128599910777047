import { useFlags } from "@resource/client-ffs";
import { useAuthContext } from "auth/context";
import { useFlagsLoading } from "components/IdentifyUserWrapper";
import config from "config";
import FeatureFlags from "generated/FeatureFlags";
import React, {
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { analytics } from "react-hooks/useAnalytics";
import semver from "semver";
import useWindowMessageListener, {
  Message as WindowMessage,
  OutboundMessage as OutboundWindowMessage,
} from "utils/useWindowMessageListener";

interface ExtensionControllerContextValue {
  panelOpenState: boolean;
  setPanelOpenState: (value: boolean) => void;
  sendMessageToParent: (message: OutboundWindowMessage) => void;
  updateAvailable?: string;
  extensionVersion?: string;
  parentHref: string | null;
}

const ExtensionControllerContext = React.createContext<
  ExtensionControllerContextValue | undefined
>(undefined);

export const useExtensionController = () => {
  const context = useContext(ExtensionControllerContext);
  if (context === undefined) {
    throw new Error("ExtensionControllerContext must be initialized first.");
  }
  return context;
};

export function ExtensionControllerProvider({
  children,
}: {
  children?: ReactNode;
}) {
  const allFlags = useFlags();
  const [currentParentHref, setCurrentParentHref] = useState<string | null>(
    null
  );
  const [panelIsOpenInExtension, setPanelIsOpenInExtension] = useState(false);
  const {
    [FeatureFlags.GREENHOUSE_MESSAGE_CTA]: enableMessageCtaButtons,
    [FeatureFlags.CHROME_EXTENSION_PUBLISHED_VERSION]: publishedVersion,
  } = allFlags;
  const { user, zeusInfo, loading: userLoading } = useAuthContext();

  const [version, setVersion] = useState("");
  const [updateAvailable, setUpdateAvailable] = useState<string>();
  const { loading: flagsLoading } = useFlagsLoading();

  const sendMessageToParent = useCallback((message: OutboundWindowMessage) => {
    window.parent.parent.postMessage(message, "*");
  }, []);

  useEffect(() => {
    if (version && publishedVersion && semver.lt(version, publishedVersion)) {
      const dismissedVersion = localStorage.getItem("dismissedVersion");
      if (dismissedVersion !== publishedVersion) {
        setUpdateAvailable(publishedVersion);
      } else {
        setUpdateAvailable(undefined);
      }
    }
  }, [version, publishedVersion]);

  const setPanelOpenState = (newPanelOpenState: boolean) => {
    // As of version 2.1.0, the iframes handle all panel state management
    if (version && semver.gte(version, "2.1.0")) {
      sendMessageToParent({
        command: "set-panel-open-state",
        value: newPanelOpenState,
      });
    } else {
      // Deprecated - remove after 2.0.12 is no longer in the wild
      sendMessageToParent("toggle-panel");
      // Also send the new message in case the version isn't initialized yet
      sendMessageToParent({
        command: "set-panel-open-state",
        value: newPanelOpenState,
      });
    }

    // Set the persisted state
  };

  useEffect(() => {
    // On mount, set the panel state
    const url = new URL(window.location.href);
    const guideOpenParam = url.searchParams.get("guide_open");
    if (guideOpenParam === "1") {
      setPanelOpenState(true);
    }

    // Also send legacy commands for old extension clients waiting
    // for permission to show buttons on the Greenhouse page
    sendMessageToParent("show-persistent-button");

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const listener = (msg: WindowMessage) => {
    console.debug(`[Msg Received From Extension]`, msg);
    if (typeof msg.data === "object" && "command" in msg.data) {
      switch (msg.data.command) {
        case "version":
          setVersion(msg.data.value);
          break;
        case "panel-open-state":
          setPanelIsOpenInExtension(msg.data.value);
          break;
        case "parent-href":
          setCurrentParentHref(msg.data.value);
          break;
        case "track-event":
          analytics.track(
            msg.data.value.eventName,
            msg.data.value.eventProperties
          );
          break;
        default:
      }
    }
  };

  useWindowMessageListener(
    listener,
    `chrome-extension://${config.EXTENSION_ID}`
  );

  useEffect(() => {
    sendMessageToParent({ command: "request-version" });
    sendMessageToParent({ command: "request-panel-open-state" });
    sendMessageToParent({ type: "request-parent-href" });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!flagsLoading) {
      sendMessageToParent({
        command: "flags-updated",
        value: JSON.stringify(allFlags),
      });

      // Also send legacy commands for old extension clients waiting
      // for permission to show buttons on the Greenhouse page
      if (enableMessageCtaButtons) {
        sendMessageToParent({ command: "show-message-button" });
      }
    }
  }, [flagsLoading, allFlags, sendMessageToParent, enableMessageCtaButtons]);

  useEffect(() => {
    if (user && zeusInfo) {
      sendMessageToParent({
        command: "organization-context-updated",
        value: {
          organization: {
            zeusStatus: zeusInfo.status,
          },
        },
      });
      sendMessageToParent({
        command: "authentication-context-updated",
        value: {
          isLoggedIn: true,
        },
      });
    } else if (!user && !userLoading) {
      sendMessageToParent({
        command: "authentication-context-updated",
        value: {
          isLoggedIn: false,
        },
      });
    }
  }, [zeusInfo, user, sendMessageToParent, userLoading]);

  /**
   * On mount and when the zeusInfo of the org changes, we need to
   * notify the chrome extension so it can update its cache and
   * not need to wait for iframes to load to get the latest info.
   */
  useEffect(() => {
    if (user && zeusInfo) {
      sendMessageToParent({
        command: "organization-context-updated",
        value: {
          organization: {
            zeusStatus: zeusInfo.status,
          },
        },
      });
    }
  }, [zeusInfo, user, sendMessageToParent]);

  useEffect(() => {
    sendMessageToParent({ type: "request-parent-href" });
  }, [sendMessageToParent]);

  return (
    <ExtensionControllerContext.Provider
      value={{
        panelOpenState: panelIsOpenInExtension,
        setPanelOpenState,
        sendMessageToParent,
        updateAvailable,
        extensionVersion: version,
        parentHref: currentParentHref,
      }}
    >
      {children}
    </ExtensionControllerContext.Provider>
  );
}
