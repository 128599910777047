// AUTOGENERATED FILE. DO NOT EDIT.
// Run 'yarn gen:ff-enum' to update.
enum FeatureFlags {
  ADD_OFFICE_EMAIL_TEMPLATE_VARIABLE = "add-office-email-template-variable",
  ADD_STAGES_BULK_ACTION = "add-stages-bulk-action",
  ADD_TO_CALENDAR = "add-to-calendar",
  ATSSYNC_PAGE_FETCH_DELAY = "atssync-page-fetch-delay",
  ATSSYNC_PAGE_SIZE = "atssync-page-size",
  ATTACH_EMAIL_CALENDAR_FILES = "attach-email-calendar-files",
  BRAND_KITS_EDIT_IN_JOBS_AND_GUIDES = "brand-kits-edit-in-jobs-and-guides",
  BRAND_KITS_IN_TEMPLATES_DISPLAY = "brand-kits-in-templates-display",
  BULL_MQ_1 = "bull-mq-1",
  BULL_MQ_2 = "bull-mq-2",
  BULL_MQ_MIGRATOR_BATCH_SIZE = "bull-mq-migrator-batch-size",
  BULL_MQ_MIGRATOR_DELAY_TIME = "bull-mq-migrator-delay-time",
  BULL_MQ_MIGRATOR_FILTER = "bull-mq-migrator-filter",
  CANDIDATE_AUTH_V_1 = "candidate-auth-v1",
  CANDIDATE_FEEDBACK_ZEUS = "candidate-feedback-zeus",
  CANDIDATE_FIXED_TOOLBAR = "candidate-fixed-toolbar",
  CANDIDATE_IDENTITY_MAGIC_TOKENS = "candidate-identity-magic-tokens",
  CANDIDATE_IDENTITY_PAGE_VIEWS = "candidate-identity-page-views",
  CANDIDATE_IDENTITY_REPLIES = "candidate-identity-replies",
  CANDIDATE_LOVE_STEP = "candidate-love-step",
  CANDIDATE_NEW_THREADS = "candidate-new-threads",
  CANDIDATE_TIMEZONE = "candidate-timezone",
  CANDIDATES_TAB = "candidates-tab",
  CC_RECRUITER = "cc-recruiter",
  CHROME_EXTENSION_PUBLISHED_VERSION = "chrome-extension-published-version",
  CODESIGNAL_INTEGRATION = "codesignal-integration",
  COLLAPSE_MESSAGE_COMPOSER = "collapse-message-composer",
  CONSOLIDATED_INTERVIEW_PLAN_EDITOR = "consolidated-interview-plan-editor",
  CONTENT_EDITOR_GIFS = "content-editor-gifs",
  CONTRACTOR_USER_LIST = "contractor-user-list",
  COPY_SETTINGS_FROM_A_JOB = "copy-settings-from-a-job",
  CREATE_A_GUIDE_AND_SHARE_WITH_CANDIDATE = "create-a-guide-and-share-with-candidate",
  CURRENT_STAGE_NEW_EDITOR_FIXES = "current-stage-new-editor-fixes",
  CUSTOMER_TRIAL = "customer-trial",
  DASHBOARD_INBOX = "dashboard-inbox",
  DISABLE_DASHBOARD_GUIDES = "disable-dashboard-guides",
  DISABLE_GUIDE_START = "disable-guide-start",
  DISABLE_INVITE = "disable-invite",
  DISABLE_USERS = "disable-users",
  DOMAIN_RESERVATIONS = "domain-reservations",
  DOUBLE_WRITE_JRGTS_FROM_STI = "double-write-jrgts-from-sti",
  DROP_CURRENT_STAGE_BY_HEURISTIC = "drop-current-stage-by-heuristic",
  EDIT_INTERVIEW_PLAN_IN_EXTENSION = "edit-interview-plan-in-extension",
  EDIT_INTERVIEWS_BULK_ACTION = "edit-interviews-bulk-action",
  EDIT_STAGES_BULK_ACTION = "edit-stages-bulk-action",
  EDITOR_LINE_ACTIONS = "editor-line-actions",
  EMAIL_EVENT_TRACKING = "email-event-tracking",
  EMAIL_TEMPLATE_JOB_ROLE_NAME_VARIABLE = "email-template-job-role-name-variable",
  EMAIL_THREAD_HEADERS = "email-thread-headers",
  EMBED_AVAILABILITY_IN_GREENHOUSE_SCHEDULER = "embed-availability-in-greenhouse-scheduler",
  EMBED_AVAILABILITY_IN_STAGE_TRACKER = "embed-availability-in-stage-tracker",
  ENABLE_CHAT_ADDON = "enable-chat-addon",
  ENABLE_FEEDBACK_ADDON = "enable-feedback-addon",
  ENABLE_GOODTIME_VARIABLE_REPLACE = "enable-goodtime-variable-replace",
  ENABLE_OVERLAY_CALENDARS = "enable-overlay-calendars",
  ENABLE_SCHEDULER_ADDON = "enable-scheduler-addon",
  ENABLE_SOURCING_ADDON = "enable-sourcing-addon",
  ENFORCE_PERMISSIONS = "enforce-permissions",
  EXTENSION_LINK_FLOW = "extension-link-flow",
  EXTENSION_UPDATE_BANNER = "extension-update-banner",
  FAN_OUT_JOB_ROLE_GUIDE_TEMPLATE_STAGES_PROPAGATION = "fan-out-job-role-guide-template-stages-propagation",
  FAN_OUT_STAGES_PROPAGATION = "fan-out-stages-propagation",
  FILE_UPLOAD = "file-upload",
  GATED_CHAT_LANDING_PAGE = "gated-chat-landing-page",
  GATED_FEEDBACK_LANDING_PAGE = "gated-feedback-landing-page",
  GATED_SOURCING_LANDING_PAGE = "gated-sourcing-landing-page",
  GLOBAL_GH_EXTENSION = "global-gh-extension",
  GREENHOUSE_EMAIL_TEMPLATE_DISPLAY = "greenhouse-email-template-display",
  GREENHOUSE_EMAIL_TEMPLATE_SYNC = "greenhouse-email-template-sync",
  GREENHOUSE_MESSAGE_CTA = "greenhouse-message-cta",
  GUIDE_GENERATION_BY_URL = "guide-generation-by-url",
  GUIDE_GENERATION_BY_URL_FALLBACK = "guide-generation-by-url-fallback",
  GUIDE_HIRING_DECISIONS = "guide-hiring-decisions",
  GUIDE_METRICS_ENABLED = "guide-metrics-enabled",
  GUIDE_PREVIEW_PAGE = "guide-preview-page",
  GUIDE_PROCESS_HACKS = "guide-process-hacks",
  GUIDE_SETTINGS_PAGE = "guide-settings-page",
  GUIDES_FILTER_BY_NAME = "guides-filter-by-name",
  HIDE_INTERVIEWS_BULK_ACTION = "hide-interviews-bulk-action",
  HIDE_ORGANIZATION_SWITCHER = "hide-organization-switcher",
  HIJACK_GREENHOUSE_BUTTONS = "hijack-greenhouse-buttons",
  ICS_FILES_IN_MESSAGES = "ics-files-in-messages",
  INTERVIEW_HASURA_DB_TRIGGER_REPLACEMENT = "interview-hasura-db-trigger-replacement",
  INTERVIEW_PROPAGATION_STRATEGY = "interview-propagation-strategy",
  INTERVIEW_SCHEDULER_ON_GUIDES = "interview-scheduler-on-guides",
  INTERVIEW_TAB_IN_JOBS = "interview-tab-in-jobs",
  INTERVIEWS_IN_JOB_SETUP_FLOW = "interviews-in-job-setup-flow",
  INTERVIEWS_RICH_BLOCK = "interviews-rich-block",
  INVITE_WITH_GUIDE_POST = "invite-with-guide-post",
  JRGTS_ALLOWS_PROPAGATION = "jrgts-allows-propagation",
  LEVER_SANDBOX_CUSTOMER_IDS = "lever-sandbox-customer-ids",
  LINK_GLOBAL_TEMPLATES = "link-global-templates",
  LOCAL_WEBHOOKS_TESTING = "local-webhooks-testing",
  MAINTENANCE_MODE = "maintenance-mode",
  MANAGE_MESSAGE_RECIPIENTS = "manage-message-recipients",
  MANUAL_EMAIL_RENDERING = "manual-email-rendering",
  MERGE_SYNC = "merge-sync",
  MERGESYNC_CANDIDATE_APPLICATION_PROPAGATION = "mergesync-candidate-application-propagation",
  MERGESYNC_CANDIDATE_PROPAGATION = "mergesync-candidate-propagation",
  MERGESYNC_INTERVIEW_PROPAGATION = "mergesync-interview-propagation",
  MERGESYNC_JOB_PROPAGATION = "mergesync-job-propagation",
  MERGESYNC_USER_PROPAGATION = "mergesync-user-propagation",
  MESSAGE_COMPOSER = "message-composer",
  MESSAGE_PRIOR_TO_INVITE = "message-prior-to-invite",
  METRICS_TO_TOP_BAR = "metrics-to-top-bar",
  MIGRATE_BATCH_ETI_DECOUPLING = "migrate-batch-eti-decoupling",
  MIGRATE_BATCH_GUIDE_JOB_ROLE_NAME = "migrate-batch-guide-job-role-name",
  MIGRATE_BATCH_LEGACY_STAGE_SYNC = "migrate-batch-legacy-stage-sync",
  MIGRATE_BATCHSIZE_BACKFILL_GLOBAL_JOB_TEMPLATE = "migrate-batchsize-backfill-global-job-template",
  MIGRATE_BATCHSIZE_BACKFILL_JOB_VERSION = "migrate-batchsize-backfill-job-version",
  MIGRATE_BATCHSIZE_GENERIC = "migrate-batchsize-generic",
  MIGRATE_BATCHSIZE_GUIDE_ORGANIZATION_FK = "migrate-batchsize-guide-organization-fk",
  MIGRATE_BATCHSIZE_LINK_DEFAULT_TEMPLATE = "migrate-batchsize-link-default-template",
  MIGRATE_BATCHSIZE_POINT_OF_CONTACT = "migrate-batchsize-point-of-contact",
  MIGRATE_BATCHSIZE_STAGE_VERSION = "migrate-batchsize-stage-version",
  MINIMAL_EMAIL_NOTIFICATIONS = "minimal-email-notifications",
  MULTIPLE_MESSAGE_TEMPLATES = "multiple-message-templates",
  NEW_EDITOR_INTERVIEWING_LOADING_ENHANCEMENT = "new-editor-interviewing-loading-enhancement",
  NEW_FEEDBACK_ALGORITHM = "new-feedback-algorithm",
  NEW_GUIDE_ARCHITECTURE = "new-guide-architecture",
  NEW_GUIDE_EDITOR = "new-guide-editor",
  NEW_GUIDE_STEPPER = "new-guide-stepper",
  NEW_GUIDES_LIST = "new-guides-list",
  NEW_INTERVIEW_TEMPLATES = "new-interview-templates",
  NEW_JOB_SETUP_FLOW = "new-job-setup-flow",
  NEW_MOVE_TO_STAGE = "new-move-to-stage",
  O_365_SENDING = "o365-sending",
  PERSIST_APOLLO_CACHE = "persist-apollo-cache",
  PERSISTENT_EXTENSION_BUTTON = "persistent-extension-button",
  PITCH_PAGES = "pitch-pages",
  POLL_INTERVAL_CONFIG = "poll-interval-config",
  PREPEND_URLS_TO_ICS_FILES = "prepend-urls-to-ics-files",
  PREVENT_CANDIDATE_PII_STORAGE = "prevent-candidate-pii-storage",
  PROPAGATE_GLOBAL_JOB_TEMPLATE = "propagate-global-job-template",
  QA_ASSIST_FEATURES = "qa-assist-features",
  READ_EVENT_DECOUPLING = "read-event-decoupling",
  RECRUITER_ACTIVATION_TARGETED_WORKFLOWS = "recruiter-activation-targeted-workflows",
  REMOVE_EVENT_STAGE_READING = "remove-event-stage-reading",
  REMOVE_STAGES_BULK_ACTION = "remove-stages-bulk-action",
  REPLACEMENT_DATA_FOR_EVENT_TEMPLATE_INSTALLATIONS = "replacement-data-for-event-template-installations",
  REPLY_VIA_EMAIL = "reply-via-email",
  REQUEST_AVAILABILITY = "request-availability",
  RESEND_INVITATIONS = "resend-invitations",
  RESTORE_JOB_ROLE_GUIDE_TEMPLATE_CHANGES = "restore-job-role-guide-template-changes",
  SAVE_TEMPLATE_ON_SEND_PROMPT = "save-template-on-send-prompt",
  SELF_SERVE_DOMAIN_RESERVATIONS = "self-serve-domain-reservations",
  SHOW_EXTENSION_OMNIBUTTON = "show-extension-omnibutton",
  SHOW_GLOBAL_JOB_TEMPLATES = "show-global-job-templates",
  SLACK_SALES_NOTIFICATIONS = "slack-sales-notifications",
  STAGE_HASURA_DB_TRIGGER_REPLACEMENT = "stage-hasura-db-trigger-replacement",
  SYNC_ATS_JOBS = "sync-ats-jobs",
  SYNC_SLACK_BACKGROUND = "sync-slack-background",
  TRUST_SCORE_SURVEY = "trust-score-survey",
  UPDATER_AGENCY_LIST = "updater-agency-list",
  USE_DATALOADERS = "use-dataloaders",
  USE_GUIDE_TEMPLATES = "use-guide-templates",
  USE_SEND_GUIDE_EMAIL_ON_SUBMIT_REFACTOR = "use-send-guide-email-on-submit-refactor",
  V_2_INSIGHTS_DASHBOARDS = "v2-insights-dashboards",
  VIEW_INTERVIEW_TEMPLATE_INSTALLATIONS_LIST = "view-interview-template-installations-list",
  ZEUS = "zeus",
  ZEUS_DELETE_POST = "zeus-delete-post",
  ZEUS_FIXED_TOOLBAR = "zeus-fixed-toolbar",
  ZEUS_POST_WITHOUT_NOTIFYING = "zeus-post-without-notifying",
  ZEUS_PREVIEW_EMAILS = "zeus-preview-emails",
  ZEUS_RENDER_EMAIL_CONTENT = "zeus-render-email-content",
  ZEUS_RENDER_PLAINTEXT_CONTENT = "zeus-render-plaintext-content",
  ZEUS_SHARE_A_GUIDE_WITH_A_CANDIDATE = "zeus-share-a-guide-with-a-candidate",
  ZEUS_TRANSITION = "zeus-transition",
}

export default FeatureFlags;
