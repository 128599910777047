type Entity =
  | "survey"
  | "message"
  | "guide"
  | "interview plan"
  | "interview"
  | "interview team"
  | "follower"
  | "recipient"
  | "job template"
  | "team member"
  | "cover image"
  | "stage default"
  | "overview content";

const mapEntityToReadableName = (entity: Entity, plural = false): string => {
  switch (entity) {
    case "overview content":
      return "overview";
    case "job template":
      return plural ? "guide templates" : "guide template";
    case "stage default":
      return plural ? "stage messages" : "stage message";
    default:
      return plural ? `${entity}s` : entity;
  }
};

/**
 *
 * @param type the type of entity
 * @param options whether to pluralize and/or capitalize the first letter.
 *   Both default to false
 * @returns the formatted entity string
 */
export const formatEntity = (
  type: Entity,
  options?: { plural?: boolean; capitalize?: boolean }
): string => {
  const entity = mapEntityToReadableName(type, options?.plural);
  if (options?.capitalize) {
    return `${entity[0]?.toUpperCase() ?? ""}${entity.slice(1)}`;
  }
  return entity;
};
